import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { CurrentTrackPlayingContext, MusicPlayBlockContext, MusicPlayerVisibilityContext, MusicTrackContext, MusicTracksContext, PlayNextContext, ShowCreatePlayListContext, userDetailsContext, UserloggedContext } from 'components/Context/UserContext';
import parse from 'html-react-parser';
import { useContext, useEffect, useRef, useState } from 'react';
import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { apiCall } from "utils/httpClient";
import Google from "../../../assets/images/google.png";
import loader from "../../../assets/images/loading_wbg.gif";
import { default as ApiEndPoint, default as apiEndPoints } from "../../../utils/apiEndPoints";
import { AllImages, emojiRegex, RemoveDuplicates, VideoPlaylistShareUrl } from "../../../utils/constants";
import "./style.css";

function IndividualBhaktipad() {
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const [playingBlock, setPlayingBlock] = useContext(MusicPlayBlockContext);

  const [lyrics, setLyrics] = useState("");
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const { track, setTrack } = useContext(MusicTracksContext);
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const location = useLocation();
  const { trackDetails, durations, progress } = location.state || {};
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { currentTrackPlaying, setCurrentTrackPlaying } = useContext(CurrentTrackPlayingContext);
  const [videoTitles, setVideoTitles] = useState([]);
  const [img, setImg] = useState([]);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(durations || 0);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [fav, setFav] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [scrollLast, setScrollLast] = useState(true);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [userPlayList, setUserPlayList] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [previouslySelectedUserPlaylistArray, setPreviouslySelectedUserPlaylistArray] = useState([]);
  const [currentlySelectedUserPlaylistArray, setCurrentlylySelectedUserPlaylistArray,] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [trackDeatilsUsingParams, setTrackDeatilsUsingParam] = useState();


  const [isPopupVisible, setPopupVisible] = useState(null);
  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);
  const navigate = useNavigate();
  const [tracks, setTracks] = useState([]);
  const { AlbumId } = useParams();
  const { TrackId } = useParams();
  const [isNavigated, setIsNavigated] = useState(false);


  useEffect(() => {
    fetchData();

    if (!track?.id && !playNextTrack?.id && !trackDeatilsUsingParams && !currentTrackPlaying?.id && TrackId) {
      fetchDataUsingParams();
    }
  
    setFav((currentTrackPlaying?.isFavourite) ? (currentTrackPlaying?.isFavourite) : (trackDeatilsUsingParams?.isFavourite))
  }, [track, singleTrack, playNextTrack, trackDeatilsUsingParams, currentTrackPlaying]);


  useEffect(() => {
    if (currentTrackPlaying && currentTrackPlaying?.id) {
      const newUrl = `/individualBhaktipad/${currentTrackPlaying?.id}`;
      window.history.replaceState(null, '', newUrl); 
    }
  }, [currentTrackPlaying]);
  



  // useEffect(() => {
  //   console.log('TrackId',TrackId)
  //   if (TrackId) {
  //     fetchDataUsingParams();
  //   }
  // }, [TrackId]);


  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };

  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };

  useEffect(() => {
    if (durations) {
      setDuration(durations);
    }
    if (progress) {
      setPlayed(progress / durations);
    }
    setFav(currentTrackPlaying?.isFavourite)
  }, [durations]);

  useEffect(() => {
    if (playerRef?.current && progress !== undefined) {
      playerRef?.current?.seekTo(progress / duration);
    }
  }, [progress, duration]);

  const fetchData = async () => {
    if ((Array.isArray(track) && track.length !== 0) || singleTrack?.id || playNextTrack?.id) {
      if (playNextTrack?.id) {
        if ((Array.isArray(track) && track.length !== 0) || track?.trackId) {
          if (currentTrackIndex >= 0) {
            track.splice(currentTrackIndex + 1, 0, playNextTrack);
          }
        } else {
          let trackList = [];
          trackList[0] = singleTrack;
          trackList.splice(currentTrackIndex + 1, 0, playNextTrack);
          setTracks(trackList.map((item) => item.trackId));
          setVideoTitles(trackList.map((item) => item.title));
          setImg(trackList.map((item) => item.thumbnailUrl));
        }
      } else {
        setCurrentTrackIndex(0);
      }


      if ((Array.isArray(track) && track.length !== 0) || track?.trackId) {
        setTracks(track.map((item) => item.trackId));
        setVideoTitles(track.map((item) => item.title));
        setImg(track.map((item) => item.thumbnailUrl));
      }

      if (singleTrack?.trackId) {
        var trackId = [singleTrack?.trackId];
        var title = [singleTrack?.title];
        var thumbnailUrl = [singleTrack?.thumbnailUrl];
        setTracks(trackId);
        setVideoTitles(title);
        setImg(thumbnailUrl);
      }
      setPlaying(true);

    } else {
      setIsMusicPlayerVisible(true)
      if (trackDeatilsUsingParams) {
        var trackId = [trackDeatilsUsingParams?.trackId];
        var title = [trackDeatilsUsingParams?.title];
        var thumbnailUrl = [trackDeatilsUsingParams?.thumbnailUrl];
        setTracks(trackId);
        setVideoTitles(title);
        setImg(thumbnailUrl);
      }
    }
  };

  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/")
                      onChange({ true: true });
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button
                    className="no-btn"
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={() => {
                    navigate("/");
                    onClose();
                  }}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

 

  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };


  async function fetchDataUsingParams(fetchPage = 0) {
    try {
      if (auth) {
        if (userDetails?.subscriptionPlan?.subscriptionLevel === 2) {
          const res = await apiCall('GET', `${apiEndPoints.GETTRACKDETAILSBYTRACKID}/${TrackId}`);
          const data = res;
          if (data?.data) {
            const record = data?.data;
            setTrackDeatilsUsingParam(record)
            // handleTitleClick(record)
            setPlayingBlock(true)
            setSingleTrack([])
            setTrack([])
            setPlayNextTrack([])
            let trackList = [];
            trackList[0] = record;
            setTrack(trackList);
            setIsMusicPlayerVisible(true);
          }
        }
        else {
          takeSubscription();
        }
      } else {
        toast.error("Please log in and try again.");
        navigate(`/`)
      }

    } catch (error) {
      console.error('Error fetching audio tracks:', error);
    }
  }

  const handleClose = () => {
    navigate(-1)
  };

  async function toggleFavoriteTrack() {
    try {
      const isCurrentlyFavorite = fav;
      const method = isCurrentlyFavorite ? "DELETE" : "POST";
      const endpoint = isCurrentlyFavorite
        ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${(currentTrackPlaying?.id) ? (currentTrackPlaying?.id) : (trackDeatilsUsingParams?.id)}`
        : `${apiEndPoints.TRACKSFAVORITE}/${(currentTrackPlaying?.id) ? (currentTrackPlaying?.id) : (trackDeatilsUsingParams?.id)}`;
      setFav(!isCurrentlyFavorite)
      const data = await apiCall(method, endpoint);
    } catch (err) {
      toast.error("Something went wrong, try again");
    }
  }

  const openModal = (id) => {
    setIsOpen(true);
    setCurrentlyPlaylingVidId(id)

  };

  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);


  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };


  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: '',
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: ''
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : prev => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
          contentType: 'YOUTUBE_TRACK'
        };
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
            openModal(currentlyPlaylingVidId);
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setPlaylistBtnLding(false);
        }
      } else {
        setIsValidPlaylistName(true);
      }
    } else {
    }
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const createPlaylist = () => {
    setIsOpen(false);
    setShowCreatePlayListModel(true);
  };
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: 'YOUTUBE_TRACK'
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const handleCopyLink = (id) => {
    setTrackDetailsForShare(currentTrackPlaying?.audioAlbum?.id)
    // setPopupVisible(null);
  };

  useEffect(() => {
    if (currentTrackPlaying?.lyrics) {
      setLyrics(currentTrackPlaying?.lyrics);
      setIsMusicPlayerVisible(true)
    } else if (trackDeatilsUsingParams?.lyrics) {
      setLyrics(trackDeatilsUsingParams?.lyrics);
      setIsMusicPlayerVisible(true)
    }
    else {
      setLyrics('');
      setIsMusicPlayerVisible(true)
    }
  }, [currentTrackPlaying, trackDeatilsUsingParams, isMusicPlayerVisible]);

  function extractArtists(title) {
    const match = title?.match(/\(feat\. (.+?)\)/);
    return match ? match[1] : 'No info Available';
  }

  const [check, setCheck] = useState(false)

  // const artists = extractArtists(currentTrackPlaying?.title ? currentTrackPlaying?.title : trackDeatilsUsingParams?.title);
  const artists = currentTrackPlaying?.artist || trackDeatilsUsingParams?.artist || "No Info Available";
  useEffect(() => {
    setIsMusicPlayerVisible(true)
    document.querySelector(".padPlay ").classList.add("myextra-class");
    return () => {
      setIsMusicPlayerVisible(true)
      document.querySelector(".padPlay ").classList.remove("myextra-class");
    };
  }, [location]);


  return (
    <section className="individualBhaktipad-page">
      <div className="individualBhaktipad-page-title-outer">
        <div className="individualBhaktipad-page-title">
          <span className="my-favorites-d-flex" onClick={handleClose}>
            <img
              className="my-favorites-c-pointer"
              src={AllImages.YellowArrow}
              alt="Back Arrow"
              title="Back Arrow"
            ></img>
          </span>
        </div>

        <div className="list-of-play-search">
          <div className="p-list-search-wrap">
            <div className="search-input">
              <span className="search-icon">
                <img src={AllImages.Search} alt="Search" title="Search" />
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Video"
              />
            </div>
            <div className="searchIcon-outer searchTooltip">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
              <span className="tooltiptext">{"Search"}</span>
            </div>
            <div className="filter-icon sortbyTooltip">
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="albumOuter">
        <p className="playingFrom">PLAYING FROM ALBUM</p>
        <p className="albumName">
          <span className="my-favorites-d-flex" onClick={handleClose}>
            <img
              className="my-favorites-c-pointers"
              src={AllImages.YellowArrow}
              alt="Back Arrow"
              title="Back Arrow"
            ></img>
          </span>
          {(currentTrackPlaying?.title) ? (currentTrackPlaying?.title) : (trackDeatilsUsingParams?.title)}
        </p>
        <p className="album">Artist Name : - {artists}</p>
      </div>

      <div className="albumInfo">
        <div className="left" style={{ display: check ? "none" : "block" }}>
          <img src={(currentTrackPlaying?.thumbnailUrl) ? (currentTrackPlaying?.thumbnailUrl) : (img)} className="topImg" alt="img" />
          <div className="lyricsMobileViewOuter">
            <p className={`lyrics mobileOnly ${isMusicPlayerVisible ? "" : ""}`}>
              {currentTrackPlaying?.lyrics ? parse(currentTrackPlaying.lyrics) : ""}
            </p>
            <div className="controlsTopInner">
              <div class="controlsTopInner">
                <div className="favTooltip">
                  <img
                    onClick={() => toggleFavoriteTrack()}
                    src={fav ? AllImages.Favorite_sel : AllImages?.Fav}
                    alt="img"
                  ></img>
                  <span className="tooltiptext">
                    {!fav ? "Mark Favorite" : "Unfavorite"}
                  </span>
                </div>

                <div className="favTooltip">
                  <img
                    className="video-play-mr-10"
                    class=""
                    alt="img"
                    src={AllImages?.TablePlus}
                    onClick={() => openModal((currentTrackPlaying?.id) ? (currentTrackPlaying?.id) : (trackDeatilsUsingParams?.id))}
                  ></img>
                  <span className="tooltiptext">
                    {" "}
                    {"Add to My Playlists"}
                  </span>
                </div>

                <div className="outer"
                  onClick={() => handleCopyLink((currentTrackPlaying?.id) ? (currentTrackPlaying?.id) : (trackDeatilsUsingParams?.id))}
                >
                  <RWebShare
                    data={{
                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${((currentTrackPlaying?.id) ? (currentTrackPlaying?.id) : (trackDeatilsUsingParams?.id))}`,
                      title: 'Bhakti Pad Share',
                    }}
                    onClick={() => console.log('share')}
                  >
                    <div className="copyTooltip">
                      <img src={AllImages?.CopyGray} className="more" alt="img" />
                      <span className="tooltiptext">Copy</span>
                    </div>
                  </RWebShare>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className={`right ${check ? "show" : "check"}`}>
          <p className={`lyrics ${isMusicPlayerVisible ? "" : ""}`}>
            {currentTrackPlaying?.lyrics ? parse(currentTrackPlaying.lyrics) : ""}
          </p>
        </div>

      </div>
      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={"paper"}
        >
          <DialogContent
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "320px",
              margin: 5,
            }}
          >
            <div className="add-my-playlist-modal-content">
              <div className="add-my-playlist-modal-d-flex modal-mb-5">
                <p className="add-my-playlist-modal-msg-text">
                  {"Add to My Playlists"}
                </p>
                <span
                  className="add-my-playlist-modal-close-button"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </div>
              <div>
                {userPlayList &&
                  userPlayList?.map((item, index) => (
                    <div
                      key={item.id}
                      className="add-my-playlist-modal-d-flex modal-mt-15"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox_${item.id}`}
                        name={`checkbox_${item.id}`}
                        data-playlist-id={item.id}
                        checked={isPlaylistSelected(item.id)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="add-my-playlist-checkbox-text"
                        htmlFor={`checkbox_${item.id}`}
                      >
                        {item.playListName}
                      </label>
                    </div>
                  ))}
                {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                  <p
                    onClick={fetchMoreUserPlaylistData}
                    className="fetch-more-playlist"
                  >
                    MORE+
                  </p>
                )}
              </div>
              <div className="modal-mt-15" onClick={createPlaylist}>
                <label className="add-my-playlist-create-playlist">
                  Create Playlist
                </label>
              </div>
            </div>
            {isLoading && (
              <div className="loader">
                <img src={loader} alt="loading..." />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="add-my-playlist-modal-action">
              <button
                onClick={closeModal}
                className="add-my-playlist-modal-cancel-btn"
              >
                {"Cancel"}
              </button>
              <button
                className="add-my-playlist-modal-add-btn"
                onClick={addVideoAgainstPlaylist}
              >
                {"confirm"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  className={`f-poppins my-create-playlist-modal-search-input ${isValidPlaylistName ? "invalid" : null
                    } `}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit()}
              >
                {createPlaylistBtnLding ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}

export default IndividualBhaktipad;
