import {
  faPause,
  faPlay,
  faShuffle,
  faStepBackward,
  faStepForward,
  faTimes,
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faVolumeUp,
  faPlus,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import {
  MusicPlayerVisibilityContext,
  MusicTrackContext,
  MusicTracksContext,
  UserloggedContext,
  CurrentTrackPlayingContext,
  MusicTracksTestContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  PlayNextContext,
  CurrentTrackIndex,
  StopVideoTimeContext,
  MusicPlayBlockContext
} from "components/Context/UserContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
// import ReactPlayer from "react-player/youtube";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AllImages } from "../../../utils/constants";
import "./style.css";
import debounce from "lodash.debounce";
import { apiCall } from "utils/httpClient";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { useDispatch } from 'react-redux';
import { deleteTracks } from "actions/Index";

const MusicPlayer = () => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(null);
  // const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const { track, setTrack } = useContext(MusicTracksContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);

  const location = useLocation();
  const [tracks, setTracks] = useState([]);
  const [playingBlock, setPlayingBlock] = useContext(MusicPlayBlockContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);
  const [isContinuousPlay, setIsContinuousPlay] = useState(false);
  const { currentTrackPlaying, setCurrentTrackPlaying } = useContext(CurrentTrackPlayingContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const [volume, setVolume] = useState(0.8);
  const [audioTitles, setAudioTitles] = useState([]);
  const [artistName, setArtistName] = useState([]);
  const [img, setImg] = useState([]);
  const [played, setPlayed] = useState(0);
  const [muted, setMuted] = useState(0);
  const [autoPlayBlockTrackId, setAutoPlayBlockTrackId] = useState(0);
  const [volumeBeforeMute, setVolumeBeforeMute] = useState(0);
  const navigate = useNavigate();
  const [isNavigated, setIsNavigated] = useState(false);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [stopTime, setStopTime] = useContext(StopVideoTimeContext);


  const dispatch = useDispatch();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1]; 
    if (pathSegments.includes('individualBhaktipad') && !isNaN(lastSegment)) {
      const trackId = Number(lastSegment);
      // console.log('TrackId:', trackId);
      setAutoPlayBlockTrackId(trackId);
    }
  }, [location.pathname]);


  
  useEffect(() => {
    setTimeout(() => {
      if(stopTime)
      handleChange(stopTime)
    }, 300);
  }, [stopTime]);



  useEffect(() => {
    setData();
    checkLocation();
  }, [track, location.pathname]);


  useEffect(() => {
    if(currentTrackPlaying?.id)
    handlePlayCount(currentTrackPlaying?.id)
  }, [currentTrackPlaying]);

  const handlePlayPause = () => {
    const player = playerRef?.current?.getInternalPlayer();
    setPlaying(!playing);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;
      const isTextInput = activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA";
  
      if (event.code === "Space" && !isTextInput) {
        event.preventDefault();
        handlePlayPause();
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePlayPause]);
  

  useEffect(() => {    
    if (audioTitles[currentTrackIndex]) {
      if(isMusicPlayerVisible){
        document.title = `${audioTitles[currentTrackIndex]}`; 
        if ('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: audioTitles[currentTrackIndex],
            artist: artistName[currentTrackIndex],
            artwork: [
              { src: img[currentTrackIndex], sizes: '512x512', type: 'image/png' },
            ]
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            setPlaying(false);
          });
    
          navigator.mediaSession.setActionHandler('play', () => {
            setPlaying(true);
          });
           if(!isLastTrack)
           navigator.mediaSession.setActionHandler('nexttrack', handleNextTrack);
           if(!isFirstTrack)
           navigator.mediaSession.setActionHandler('previoustrack', handlePrevTrack);
        }
      }
    } else {
      document.title = 'satshrut';
    }
  }, [audioTitles, artistName, img, currentTrackIndex, setPlaying]);


  const setData = async () => {
    const updatePlayer = (trackList) => {
      setTracks(trackList.map((item) => item.url));
      setAudioTitles(trackList.map((item) => item.title));
      setImg(trackList.map((item) => item.thumbnailUrl));
      setArtistName(trackList.map((item) => (item?.artist)?(item?.artist) : "No Info Available" ));

      //console.log('playingBlock',playingBlock);
      
      
      if (playing === null && !playingBlock) { 
        setPlaying(true);
      }

      if(trackList.length==1)
      setCurrentTrackIndex(0);
    };
      if (!((Array.isArray(track) && track.length !== 0))) {
        handleCloseWhenVideoPlays();
        return;
      }
      if ((Array.isArray(track) && track.length !== 0)) {
        updatePlayer(track);
      }

      if(track[currentTrackIndex]){
        setCurrentTrackPlaying(track[currentTrackIndex])
      }
  };

  const handleNextTrack = () => {
    setCurrentTrackIndex(prevIndex => {
      const newIndex = prevIndex + 1;
      if (newIndex >= tracks.length) {
        setPlaying(null);
        handleClose();
        return prevIndex;
      } else {
        updateTrackIndex(newIndex);
        return newIndex;
      }
    });
};

  
const handlePrevTrack = () => {
  setCurrentTrackIndex(prevIndex => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
          return prevIndex;  
      } else {
          updateTrackIndex(newIndex);
          return newIndex;
      }
  });
};

const isFirstTrack = currentTrackIndex === 0;

  
  const updateTrackIndex = (newIndex) => {
    setCurrentTrackIndex(newIndex);
    if (track[newIndex]) {
      setCurrentTrackPlaying(track[newIndex]);
    }
  };
  
  const extractArtistName = (title) => {
    const match = title?.match(/\(feat\. (.+?)\)/);
    return match ? match[1] : 'No info Available';
  };  
  
  const handleShuffleTrack = () => {
    const randomIndex = Math.floor(Math.random() * tracks.length);
    updateTrackIndex(randomIndex);
  };

  const handleClose = () => {
    setPlayingBlock(false)
    clearTracks();
    setPlaying(null);
    setCurrentTrackIndex(0);
    setTimeout(() => {
      document.title = 'satshrut'
    }, 500);
    // dispatch(deleteTracks()); 
    setStopTime(null)
    }

  const handleCloseWhenVideoPlays = () => {
    setPlayingBlock(false)
    setIsMusicPlayerVisible(false);
    if(playing == true){
      setPlaying(null);
      setCurrentTrackIndex(0);
    }
    setPlaying(null);
    setCurrentTrackIndex(0);
    setTimeout(() => {
      document.title = 'satshrut'
    }, 500);
    setStopTime(null)
    // dispatch(deleteTracks()); 
  };

  const clearTracks = () => {
    setTrack([]);
    setTracks([]);
    setIsMusicPlayerVisible(false);
    setPlayingAlbumId(null)
    setPlayingShuffledAlbumId(null)
    // dispatch(deleteTracks()); 
  };
  

  const handleTrackEnd = () => {
    if((tracks.length === 1)){
      setPlaying(null);
    }
      handleNextTrack();
    
  };
  
  const handlePlayAll = () => {
    setIsContinuousPlay((prev) => !prev);
    if (!isContinuousPlay) {
    }
  };
  
  const currentVolumePercentage = Math.round(volume * 100);
  const handleVolumeChange = (newVolume) => {
    if (muted && newVolume > 0) {
      setMuted(false); // Unmute if volume is increased while muted
    }
    setVolume(newVolume);
  };
  
  
  const handleVolumeChangeMute = () => {
    setMuted((prev) => !prev);
    setVolumeBeforeMute(volume);
    setVolume(muted ? volumeBeforeMute : 0);
  };
  
  const handleOpenLyrics = () => {
    if (!(currentTrackPlaying?.lyrics)) {
      toast.warn("Lyrics not available.");
      return
    } 

    setIsMusicPlayerVisible(true)
    if (Array.isArray(tracks) && tracks.length !== 0) {
      const currentTrack = track.find(
        (track) => track.url == tracks[currentTrackIndex]
      );
      if (isNavigated) {
        navigate(-1);
      } else {
        setIsNavigated(true);
        navigate(`/individualBhaktipad/${currentTrack?.id}`, {
          state: { trackDetails: currentTrack },
        });
      }
    }
  };

  const handleProgress = (state) => {
      setPlayed(state.played);
  };
  
  const getCurrentTrackUrl = () => {
        return `${tracks[currentTrackIndex]}`;
  };


  const [duration, setDuration] = useState(0);

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const checkLocation = () => {
    if (location.pathname !== `/individualBhaktipad/${currentTrackPlaying?.id}`) {
      setIsNavigated(false);
    }
  };

  const buttonStyle = {
    border: "none",
    backgroundColor: "white",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
  };

  const iconStyle = {
    color: "#F9B004",
    backgroundColor: "white",
    border: "none",
  };

  const handlePlayCount = async (id) => {
    try {
      const response = await apiCall("PUT", `${ApiEndPoint.PLAYCOUNT}/${id}`);
      if (response?.data?.status === "200") {
        // console.log("play Count Updated for trackID",id)
      } else {
        // console.log("failed to update playCount for trackID",id)
      }
    } catch (err) {
      // console.log("failed to update playCount for trackID",id)
    }
  };
  
  

  const renderControlButton = (onClick, icon, disabled, tooltipText) => (
    <button
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle}
      title={tooltipText}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

  const renderVolumeButton = (onClick, icon, color, tooltipText) => (
    <button
      onClick={onClick}
      className="volumeImg"
      style={{ ...buttonStyle, color }}  // Apply the color passed as a parameter
      title={tooltipText}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

const renderVolumeOffButton = (onClick, icon) => (
  <button
    onClick={onClick}
    style={{
      background: "none",
      border: "none",
      padding: 0,
      cursor: "pointer",
    }}
  >
    <img
      src={icon}
      alt="volume icon"
      style={{
        width: "18px",
        height: "18px",
        display: "block",
      }}
    />
  </button>
);

const [showPlayLines, setShowPlayLines] = useState(false);


const handleChange = (e) => {
  let value = 0;
  // const value = parseFloat(e?.target?.value);
  value = parseFloat(e?.target?.value);

  // if (stopTime) {
  //   console.log('value ',e)
  //   value = parseFloat(e);
  // } else {
  //   if(e.target.value){
  //     console.log('value target',e?.target?.value)
  //     value = parseFloat(e.target.value);
  //   }
  // }

  // setPlayed(value);
  // handleSeekChange(value);

  // Ensure the value is a finite number
  if (!isNaN(value) && isFinite(value)) {
    setPlayed(value);
    handleSeekChange(value);
  } else {
    // console.error('Invalid value: ', value);
  }

  setShowPlayLines(false); // Reset the visibility
  setTimeout(() => {
    setShowPlayLines(true);
  }, 1000);


};

const handleSeekChange = useCallback(
  debounce((value) => {
      if (playerRef.current) {
        playerRef.current.seekTo(parseFloat(value));
    }
  }, 100),
  []
);





  const isLastTrack = currentTrackIndex === tracks.length - 1;

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "20px",
        position: "relative",
        zIndex: "1",
        display: isMusicPlayerVisible ? "block" : "none",
      }}
    >
      <div className={`padPlay ${isNavigated ? "myextra-class" : ""}`}>
        <div className="topSection">
          <img src={img[currentTrackIndex]} className="padPlayImg" alt="img" />
          <div className="details">
            <p className="title">
              {audioTitles[currentTrackIndex]}
            </p>
            <div className="subTitleOuter">
              <p className="subTitle">
                {artistName[currentTrackIndex]}
              </p>
              <div className="tooltipWrapper">
              <img
                src={(!currentTrackPlaying?.lyrics) ? AllImages?.LyricsNotAvailable : AllImages?.LyricsAvailable}
                style={{ width: '25px', height: '25px' }}
                className={`info ${!((!currentTrackPlaying?.lyrics)) ? 'disabled' : ''}`}
                alt="img"
                onClick={handleOpenLyrics}
              />
                <div className="tooltip">
                  <span className="tooltiptext">
                    {"View Lyrics"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomSection">
          <div className="player">
            <div className="controls">
              {renderControlButton(handleShuffleTrack, faShuffle, tracks.length === 1, "Shuffle")}
              {renderControlButton(handlePrevTrack, faStepBackward, isFirstTrack, "Prev Track")}
              <div className="padPlayInner" style={{ border: "none", backgroundColor: " white" }}>
                <button
                  onClick={handlePlayPause}
                  className="playIcon"
                  style={buttonStyle}
                  title={playing ? "Pause" : "Play"}
                >
                  <FontAwesomeIcon
                    icon={playing ? faPause : faPlay}
                    style={iconStyle}
                  />
                </button>
              </div>
              {renderControlButton(handleNextTrack, faStepForward, (isLastTrack), "Next Track")}
            </div>
          </div>

          <div className="playLines">
            {/* <span className="playerTime">
              {showPlayLines ? formatTime(played * duration) : "0:00"}
            </span> */}

            <span className="playerTime">{formatTime(played * duration)}</span>


            <input
              type="range"
              backgroundColor="#fff3e0"
              min="0"
              max="1"
              step="0.01"
              value={played}
              onChange={handleChange}
            />
            <span className="playerTime">{formatTime(duration)}</span>
          </div>




          <div className="volumeController" style={{ marginLeft: "10px"}}>
            {renderVolumeOffButton(handleVolumeChangeMute, !muted ? AllImages?.muteMusic : AllImages?.muteMusicOff,  muted ? "Unmute" : "Mute")}
            {renderVolumeButton(() => handleVolumeChange(Math.max(volume - 0.1, 0)), faMinus, "Decrease Volume")}
            <span style={{ marginLeft: "10px", width: "40px", display: "inline-block", textAlign: "center" }}>
              {currentVolumePercentage}%
            </span>
            {renderVolumeButton(() => handleVolumeChange(Math.min(volume + 0.1, 1)), faPlus, "Increase Volume")}
          </div>
        </div>


        <button onClick={handleClose} className="closeButton">
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: "26px", color: "#F9B004" }} />
        </button>
      </div>

      <div style={{ display: "none" }}>
        <ReactPlayer
          ref={playerRef}
          url={getCurrentTrackUrl()}
          playing={playing}
          onEnded={handleTrackEnd}
          volume={volume}
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </div>
    </div>
  );
};
export default MusicPlayer;
