import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/context";
import {
  userDetailsContext,
  UserloggedContext,
} from "../../Context/UserContext";
import "./style.css";
import {
  GLOBAL_URL,
  AllImages,
  RemoveDuplicates,
} from "../../../utils/constants";

function ViewMore() {
  const navigate = useNavigate();
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [auth, setAuth] = useContext(UserloggedContext);

  function LogoutFun(params) {
    navigate("/");
    signOutFun();
  }

  const handleSubcription = (item) => {
    userDetails?.subscriptionPlan?.subscriptionLevel == 2
      ? navigate("/activeSubscriptionPlan")
      : navigate("/subscriptionPlanList");
  };

  return (
    <section className="viewMore">
      <div className="userImage">
        <img
          className="dot"
          src={
            userDetails?.user?.imageUrl
              ? userDetails?.user?.imageUrl
              : AllImages?.UserImage
          }
          alt="User Image"
          title="User Image"
        ></img>
        {auth && (
          <>
            {" "}
            <p className="userName f-poppins">
              {userDetails?.user?.firstName} {userDetails?.user?.lastName}{" "}
            </p>
            <p
              className="viewProfile f-poppins"
              onClick={() => navigate("/profile")}
            >
              VIEW PROFILE
            </p>
          </>
        )}
      </div>




      <button className="button btn" onClick={() => navigate(`/eBooks`)}>
        <img
          src={AllImages?.EBbook}
          alt="Ebook"
          title="Ebook"
        ></img>
        <p class="title">E-Books</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>


      <button className="button btn" onClick={() => handleSubcription()}>
        <img
          src={AllImages?.SubscriptionsMore}
          alt="subscription"
          title="subscription"
        ></img>
        <p class="title">Subscription</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      <button className="button btn" onClick={() => navigate("/aboutUs")}>
        <img src={AllImages?.AboutUsMore} alt="about us" title="about us"></img>
        <p class="title">About Us</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      <button className="button btn" onClick={() => navigate("/fAQS")}>
        <img src={AllImages?.FaqsMore} alt="faqs" title="faqs"></img>
        <p class="title">FAQs</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      <button className="button btn" onClick={() => navigate("/videosIndex")}>
        <img
          src={AllImages?.VideoIndexMore}
          alt="video index"
          title="video index"
        ></img>
        <p class="title">Video Index</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      <button
        className="button btn"
        onClick={() => auth && navigate("/contactUs")}
      >
        <img
          src={AllImages?.ContactUsMore}
          alt="Contact us"
          title="cotact us"
        ></img>
        <p class="title">Contact Us</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      <button
        className="button btn"
        onClick={() => auth && navigate("/privacyPolicy")}
      >
        <img
          src={AllImages?.FAQs}
          alt="Contact us"
          title="privacy policy"
        ></img>
        <p class="title">Privacy Policy</p>
        <img
          className="arrrowRight"
          src={AllImages?.RightArrowOrange}
          alt="Arrow"
          title="Arrow"
        />
      </button>

      {auth && (
        <p className="logout" onClick={() => LogoutFun()}>
          log out
        </p>
      )}
      <img
        src={AllImages?.BgRoundTop}
        className="bgRound"
        alt="Background"
        title="Background"
      ></img>
    </section>
  );
}
export default ViewMore;
