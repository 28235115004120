import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Google } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import React, { useContext, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { Button, Table } from "reactstrap";
import loader from "../../../assets/images/loading_wbg.gif";
import ApiEndPoint from "../../../utils/apiEndPoints";
import {
  AllImages,
  BhaktiPadPlaylistShareUrl,
  GLOBAL_URL,
  MinutesToHoursMinutes,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
  emojiRegex,
  getImageNameFromResolution
} from "../../../utils/constants";
import { apiCall } from "../../../utils/httpClient";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import {
  ActiveTabsContext,
  AlbumFavContext,
  AudiosFavoriteListContext,
  CurrentTrackIndex,
  EBooksFavoriteListContext,
  EBooksListContext,
  EBooksSeriesFavouriteListContext,
  FavoritesPlayListContext,
  FavoritesVideoListContext,
  MusicPlayerVisibilityContext,
  MusicTrackContext,
  MusicTracksContext,
  PlayNextContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  QuestionHistoryContext,
  SharedPlayListContext,
  ShowCreatePlayListContext,
  UserPlayListContext,
  UserloggedContext,
  VideoListContext,
  userDetailsContext
} from "../../Context/UserContext";
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";
const SortingOptions = {
  NEWLY_ADDED: "NEWLY_ADDED",
  PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
  POPULAR_VIDEOS: "POPULAR_VIDEOS",
  NEWLY_PUBLISHED: "NEWLY_PUBLISHED",
  PREVIOUSLY_PUBLISHED: "PREVIOUSLY_PUBLISHED",
  LAST_MODIFIED_DATE: "LAST_MODIFIED_DATE",
  POPULAR_BHAKTI_PADS: "POPULAR_VIDEOS",
  Sort_A_To_Z: "A_TO_Z",
  Sort_Z_To_A: "Z_TO_A",
};

function Favorites() {
  const dispatch = useDispatch();
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activetabs, setActiveTabs] = useContext(ActiveTabsContext);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const [tabValue, setTabValue] = React.useState(
    activetabs.filter((item) => item.page == "myFavorites")[0].active
  );
  const [searchText, setSearchText] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [videoList, setVideoList] = useContext(VideoListContext);
  const [eBooksFavoriteList, setEBooksFavoriteList] = useContext(EBooksFavoriteListContext);
  const [eBookSeriesFavoriteList, setEBookSeriesFavoriteList] = useContext(EBooksSeriesFavouriteListContext);
  const [eBooksListData, setEBooksListData] = useContext(EBooksListContext);
  const [favoritesVideoList, setFavoritesVideoList] = useContext(FavoritesVideoListContext);
  const [favoritePlayListData, setFavoritePlayListData] = useContext(FavoritesPlayListContext);
  const [audiosFavoriteList, setAudiosFavoriteList] = useContext(AudiosFavoriteListContext);
  const [selectedFilterOp, setSelectedFilterOp] = useState(SortingOptions.PREVIOUSLY_ADDED);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const { track, setTrack } = useContext(MusicTracksContext);

  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);


  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const [albumFavList, setAlbumFavList] = useContext(AlbumFavContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);
  const [empty, setEmpty] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [scrollLast, setScrollLast] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [userPlayList, setUserPlayList] = useContext(UserPlayListContext);
  const [sharedPlaylist, setSharedPlayList] = useContext(SharedPlayListContext);
  const [sharedPlaylistCount, setSharedPlaylistCount] = useState(0);
  const [playlistTab, setPlaylistTab] = useState('videos');
  const [playlistName, setPlaylistName] = useState("");
  const [BtnLoading, setBtnLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [previouslySelectedUserPlaylistArray, setPreviouslySelectedUserPlaylistArray] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentlySelectedUserPlaylistArray, setCurrentlylySelectedUserPlaylistArray] = useState([]);
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [questionHistoryList, setQuestionHistoryList] = useContext(QuestionHistoryContext);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = (id) => {
    setIsOpen(true);
    setCurrentlyPlaylingVidId(id);
  };

  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue, true)) {
      setNote(inputValue);
      setIsValidNote(true);
    } else {
      setIsValidNote(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    var params = {}
    if (auth) {
      if (valid) {

        if (event === 'bhaktiPad') {
          params = {
            playListName: playlistName,
            contentType: 'YOUTUBE_TRACK'
          };
        }
        else {
          params = {
            playListName: playlistName,
            contentType: 'VIDEO'
          };
        }
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {

            if (event === 'videos') {
              navigate("/myCreatePlayListVideoAddBtnSuggested", {
                state: { id: data.id, title: data.playListName },
              });
              closeCreatedPlayListModal()
            }

            else {
              setShowCreatePlayListModel(false);
              setPlaylistName(null);
              setRefresh(!refresh);
              setPlaylistBtnLding(false);
              openModal(currentlyPlaylingVidId);
              fetchUserPlaylistData();
              // navigate("/myCreatePlayListTrackAddBtnSuggested")
            }
            setRefresh(!refresh);
          } else {
            setRefresh(!refresh);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          closeCreatedPlayListModal()
          setRefresh(!refresh);
          setBtnLoading(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: "",
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: "",
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: "YOUTUBE_TRACK",
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  const createPlaylist = () => {
    setIsOpen(false);
    setShowCreatePlayListModel(true);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [tabValue, selectedFilterOp]);

  const fetchData = () => {
    setHasMore(true);
    tabValue === "videos"
      ? favoriteList(1)
      : tabValue === "playlists"
        ? favoritePlayList(1)
        : tabValue === "ebook"
          ? EbookfavoritePlayList(1)
          : tabValue === "albums"
            ? albumfavouriteList(1) :
            tabValue === "bhaktiPad"
              ? GetFavoriteTracks(1)
              : ebookSeriesFavoritePlayList(1);
  };
  const fetchMoreData = () => {
    tabValue === "videos"
      ? favoriteList()
      : tabValue === "playlists"
        ? favoritePlayList()
        : tabValue === "ebook"
          ? EbookfavoritePlayList()
          : tabValue === "albums"
            ? albumfavouriteList()
            : tabValue === "bhaktiPad"
              ? GetFavoriteTracks()
              :
              ebookSeriesFavoritePlayList();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  const favoriteList = async (fetchPage = 0) => {
    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };

      setIsLoading(true);
      const { data } = await apiCall("POST", ApiEndPoint.MYFAVOURITES, params);
      if (data?.length > 0) {
        setIsLoading(false);
        setFavoritesVideoList(
          RemoveDuplicates(fetchPage ? data : favoritesVideoList.concat(data))
        );

        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);

        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setFavoritesVideoList([]);
        setHasMore(false);
        setPageNo(0);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setScrollLast(false);
      setHasMore(false);
    }
    isOpenSortBy && closeModalSortBy();
  };

  const favoritePlayList = async (fetchPage = 0) => {
    if (
      ![
        "NEWLY_ADDED",
        "LAST_MODIFIED_DATE",
        "PREVIOUSLY_ADDED",
        "A_TO_Z",
        "Z_TO_A",
      ].includes(selectedFilterOp)
    ) {
      setSelectedFilterOp(SortingOptions.PREVIOUSLY_ADDED);
      return;
    }
    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };
      setIsLoading(true);

      const { data } = await apiCall(
        "POST",
        ApiEndPoint.MYFAVOURITEPLAYLISTSHOW,
        params
      );
      if (data?.length > 0) {
        //

        setIsLoading(false);
        // setFavoritesVideoList(fetchPage ? data : (prev) => [...prev, ...data]);
        setFavoritePlayListData(
          RemoveDuplicates(fetchPage ? data : favoritePlayListData.concat(data))
        );

        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);

        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setFavoritePlayListData([]);
        setHasMore(false);
        setPageNo(0);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setHasMore(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModalSortBy();
  };

  const EbookfavoritePlayList = async (fetchPage = 0) => {
    if (
      ![
        "NEWLY_ADDED",
        "LAST_MODIFIED_DATE",
        "PREVIOUSLY_ADDED",
        "A_TO_Z",
        "Z_TO_A",
      ].includes(selectedFilterOp)
    ) {
      setSelectedFilterOp(SortingOptions.LAST_MODIFIED_DATE);
      return;
    }

    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };
      setIsLoading(true);

      const { data } = await apiCall(
        "POST",
        ApiEndPoint.EBOOKSFAVOURITELIST,
        params
      );
      if (data?.length > 0) {
        setIsLoading(false);

        setEBooksFavoriteList(
          RemoveDuplicates(fetchPage ? data : eBooksFavoriteList.concat(data))
        );

        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);
        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setEBooksFavoriteList([]);
        setPageNo(0);
        setHasMore(false);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setHasMore(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModalSortBy();
  };
  const ebookSeriesFavoritePlayList = async (fetchPage = 0) => {
    if (
      ![
        "NEWLY_ADDED",
        "LAST_MODIFIED_DATE",
        "PREVIOUSLY_ADDED",
        "A_TO_Z",
        "Z_TO_A",
      ].includes(selectedFilterOp)
    ) {
      setSelectedFilterOp(SortingOptions.LAST_MODIFIED_DATE);
      return;
    }

    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };
      setIsLoading(true);

      const { data } = await apiCall(
        "POST",
        ApiEndPoint.EBOOKSERIESFAVOURITELIST,
        params
      );
      if (data?.length > 0) {
        setIsLoading(false);

        setEBookSeriesFavoriteList(
          RemoveDuplicates(
            fetchPage ? data : eBookSeriesFavoriteList.concat(data)
          )
        );

        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);
        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setEBookSeriesFavoriteList([]);
        setPageNo(0);
        setHasMore(false);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setHasMore(false);
      setScrollLast(false);
    }
    isOpenSortBy && closeModalSortBy();
  };

  const removeFavorite = async (item, action) => {
    try {
      setEmpty(true);

      const apiUrl = `${action === 'add' ? ApiEndPoint.ALBUMSFAVORITE : ApiEndPoint.ALBUMSFAVORITEDELETE}/${item.id}`;
      const method = action === 'add' ? 'POST' : 'DELETE';

      const { data } = await apiCall(method, apiUrl);
      if (data) {
        const filteredFavorite = favoritesVideoList?.filter((x) => x.id !== item.id);
        setFavoritesVideoList(filteredFavorite);
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  const addVideoViewHistory = async (item) => {
    try {
      const { data } = await apiCall(
        "POST",
        `/video-view-create-histories/${item.id}`
      );
      if (data) {
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const videoPlayBtn = async (index, item) => {
    const Auth = await localStorage.getItem("userToken");
    setActiveIndex(index);
    setShowLoader(true);
    if (Auth == "01") {
      addVideoViewHistory(item);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`);
  };

  const handleTabChange = (event, newValue) => {
    setPageNo(0);
    setHasMore(true);
    setScrollLast(true);
    setTabValue(newValue);
    const updateArray = activetabs.map((item) => {
      if (item.page === "myFavorites") {
        return {
          ...item,
          active: newValue,
        };
      } else {
        return {
          ...item,
          active: "videos",
        };
      }
    });
    setActiveTabs(updateArray);
  };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },

    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });

  async function PlaylistWatchLaterRemove(item, index) {
    try {
      if (auth) {
        favoritePlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.PLAYLISTWATCHLATERREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLater(item, index) {
    try {
      if (auth) {
        favoritePlayListData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATER}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouritePlaylistRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          const removeWatchLatedVieo = favoritePlayListData.filter(
            (x) => x.id != item.id
          );
          setFavoritePlayListData(removeWatchLatedVieo);
          if (removeWatchLatedVieo.length <= 1) {
            fetchData();
          }
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const videoWatchLater = async (item, index) => {
    if (auth) {
      favoritesVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      favoritesVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  async function myFavouriteEbooksRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${item.contentType == "Transcript"
            ? ApiEndPoint.DELETEFAVOURITETRANSCRIPT
            : item.contentType == "Patra"
              ? ApiEndPoint.DELETEFAVOURITEPATRA
              : ApiEndPoint.DELETEFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          const removeEbooks = eBooksFavoriteList.filter(
            (x) => x.contentId != item.contentId
          );
          setEBooksFavoriteList(removeEbooks);
          if (removeEbooks.length <= 1) {
            fetchData();
          }
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const individualPlayListNav = (item, type) => {
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
    watchLaterSavePlayListFun(item.id);
  };
  async function watchLaterSavePlayListFun(id) {
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATERSAVE}/${id}`
        );
        if (data.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };

  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };

  const handleReadOrPlayClick = (contentUrl) => {
    if (tabValue === "ebookSeries") {
      navigate(`/eBookSeries/${contentUrl}`);
    } else {
      const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
        contentUrl
      )}`;
      window.open(newTabUrl, "_blank");
    }
  };

  function handleSearch(e) {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
      }
    }
  }

  const handleDownloadClick = async (item, index) => {
    try {
      if (auth) {
        const params = {
          ebooksContents: item.contentType === "Ebook" ? item.contentId : "",
          patraContents: item.contentType === "Patra" ? item.contentId : "",
          transcriptContent:
            item.contentType === "Transcript" ? item.contentId : "",
        };

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKDOWNLOAD}`,
          params
        );
        if (data.status == 200) {
          fileDownload(item.contentUrl, index);
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };

  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }

  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const fileDownload = async (contentUrl, index) => {
    try {
      setFileDownloadLoader(index);
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);

      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  async function myFavouriteEbookSeriesRemove(item, index) {
    try {
      if (auth) {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEREMOVEEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          const removeEbooks = eBookSeriesFavoriteList.filter(
            (x) => x.contentId != item.contentId
          );
          setEBookSeriesFavoriteList(removeEbooks);
          if (removeEbooks.length <= 1) {
            fetchData();
          }
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };

  async function myFavoriteTracksDelete(item, index) {
    try {
      if (auth) {
        audiosFavoriteList[index].isFavourite = item.isFavourite ? false : true;
        const data = await apiCall(
          "DELETE",
          `${ApiEndPoint.TRACKSFAVORITEDELETE}/${item.id}`
        );

        if (data) {
          const removeTrac = audiosFavoriteList.filter(
            (x) => x.contentId != item.contentId
          );
          setAudiosFavoriteList(removeTrac);
          if (removeTrac.length <= 1) {
            fetchData();
          }
          setRefresh(!refresh);
          setIsLoading(false);
          // toast.success("Removed from favorites successfully!");

        } else {
          setRefresh(!refresh);
          setIsLoading(false);
          // toast.success("Removed from favorites successfully!");
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
      toast.error("Something went wrong , try again");
    }
  }

  const GetFavoriteTracks = async (fetchPage = 0) => {
    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };

      setIsLoading(true);
      const { data } = await apiCall("POST", ApiEndPoint.GETFAVORITETRACKS, params);
      if (data?.content?.length > 0) {
        setIsLoading(false);
        setAudiosFavoriteList(
          RemoveDuplicates(fetchPage ? data?.content : audiosFavoriteList.concat(data?.content))
        );
        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);

        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setAudiosFavoriteList([]);
        setHasMore(false);
        setPageNo(0);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setScrollLast(false);
      setHasMore(false);
    }
    isOpenSortBy && closeModalSortBy();
  };

  const albumfavouriteList = async (fetchPage = 0) => {
    try {
      const params = {
        searchValue: searchText,
        sortColumn: selectedFilterOp,
        sortDirection: "",
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
      };

      setIsLoading(true);
      const { data } = await apiCall("POST", ApiEndPoint.GETALLFAVORITEALBUMS, params);
      // console.log('data: ', data);
      if (data?.content?.length > 0) {
        setIsLoading(false);
        setAlbumFavList(
          RemoveDuplicates(fetchPage ? data?.content : albumFavList.concat(data?.content))
        );
        setEmpty(false);
        setPageNo(fetchPage ? fetchPage : pageNo + 1);
        setHasMore(data.length == 20);

        setScrollLast(true);
      } else {
        setEmpty(true);
        setIsLoading(false);
        fetchPage && setAlbumFavList([]);
        setHasMore(false);
        setPageNo(0);
        setScrollLast(false);
      }
    } catch (err) {
      setEmpty(true);
      setIsLoading(false);
      setScrollLast(false);
      setHasMore(false);
    }
    isOpenSortBy && closeModalSortBy();
  };

  const handleFavoriteTracks = async (item, index) => {
    try {
      const apiUrl = `${ApiEndPoint.ALBUMSFAVORITEDELETE}/${item.id}`;
      const method = 'DELETE';
      const { data } = await apiCall(method, apiUrl);


      if (data.length > 0) {
        const removedAlbum = albumFavList.filter(
          (x) => x.id != item.id
        );

        setAlbumFavList(removedAlbum)

        if (removedAlbum.length <= 1) {
          fetchData();
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);


  const togglePopup = (id) => {
    // console.log('togglePopup cliked');

    setPopupVisible(isPopupVisible === id ? null : id);
  };

  // const handlePlayNext = (trackDetails) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     return;
  //   }

  //   if (trackDetails) {
  //     const newTrackList = [...track];      
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }

  //   setPopupVisible(null);
  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (trackDetails) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      return;
    }
    // const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === trackDetails?.id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };

  const handleCopyLink = (id) => {
    const trackDetails = audiosFavoriteList?.find(track => track?.id == id);
    setTrackDetailsForShare(trackDetails?.audioAlbum?.id)
    // setPopupVisible(null);
  };

  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }


  return (
    <>
      <section className={`my-favorites${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
        <InfiniteScroll
          dataLength={
            tabValue === "videos"
              ? favoritesVideoList.length
              : tabValue === "playlists"
                ? favoritePlayListData.length :
                tabValue === "albums"
                  ? albumFavList.length :
                  tabValue === "bhaktiPad"
                    ? audiosFavoriteList.length
                    : eBooksFavoriteList.length
          }
          next={() => fetchMoreData()}
          hasMore={hasMore}
          loader={
            scrollLast ? (
              isLoading && (
                <center>
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  />
                </center>
              )
            ) : (
              <p className="subtitle fancy">
                <span>End of results</span>
              </p>
            )
          }
        >
          {/* {isLoading && <Loder />} */}
          <div>
            {/*----- My Favourites -----*/}
            <div className="my-favorites-title-mt-20">
              <span
                className="my-favorites-d-flex"
              >
                <img
                  className="my-favorites-c-pointer"
                  src={AllImages.YellowArrow}
                  alt="Back Arrow"
                  title="Back Arrow"
                  onClick={() => navigate(-1)}
                ></img>
                <p className="my-favorites-title">My Favourites</p>
              </span>
              <div className="search-wrap">
                <div className="search-input">
                  <span onClick={fetchData} className="search-icon">
                    {isLoading ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 15, width: 15 }}
                      />
                    ) : (
                      <div className="searchTooltip">
                        <img
                          src={AllImages.Search}
                          alt="Search"
                          title="Search"
                        />
                        <span className="tooltiptext">{"Search"}</span>
                      </div>
                    )}
                    {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                  </span>
                  <input
                    className="f-poppins"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                    onKeyUp={handleKeyPress}
                  />
                </div>
                <div className="searchIcon-outer">
                  <img
                    src={AllImages.SearchIcon}
                    alt="Search"
                    title="Search"
                    className="searchIcon"
                  />
                </div>
                <div
                  className="filter-icon sortbyTooltip"
                  onClick={() => openModalSortBy()}
                >
                  <img
                    src={AllImages.SearchToggle}
                    alt="Search"
                    title="Search"
                  />
                  <span className="tooltiptext">{"Sort By"}</span>
                </div>
              </div>
            </div>

            {/*----- Videos, Playlists Tab Bar and Search Bar -----*/}
            <div className="my-favorites-tab-div">
              <Box className="my-favorites-tab-a-center">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#FFB200",
                      minWidth: "auto",
                    },
                  }}
                  aria-label="secondary tabs example"
                >
                  <StyledTab value="videos" label="Satsang " />
                  <StyledTab value="playlists" label="Satsang Playlist" />
                  <StyledTab value="bhaktiPad" label="Bhakti Pad" />
                  <StyledTab value="albums" label="Albums" />
                  <StyledTab value="ebook" label="E-BOOKS" />
                  <StyledTab value="ebookSeries" label="E-BOOKS Series" />

                </Tabs>
              </Box>

              <div className="search-wrap">
                <div className="search-input">
                  <span onClick={fetchData} className="search-icon">
                    {isLoading ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 15, width: 15 }}
                      />
                    ) : (
                      <div className="searchTooltip">
                        <img
                          src={AllImages.Search}
                          alt="Search"
                          title="Search"
                        />
                        <span className="tooltiptext">{"Search"}</span>
                      </div>
                    )}
                    {/* <img src={AllImages.Search} alt="Search" title="Search" /> */}
                  </span>
                  <input
                    className="f-poppins"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                    onKeyUp={handleKeyPress}
                  />
                </div>
                <div className="searchIcon-outer">
                  <img
                    src={AllImages.SearchIcon}
                    alt="Search"
                    title="Search"
                    className="searchIcon"
                  />
                </div>
                <div
                  className="filter-icon sortbyTooltip"
                  onClick={() => openModalSortBy()}
                >
                  <img
                    src={AllImages.SearchToggle}
                    alt="Search"
                    title="Search"
                  />
                  <span className="tooltiptext">{"Sort By"}</span>
                </div>
              </div>
            </div>

            {/*----- Album TabBar List -----*/}


            {/*----- My favorites Video Card -----*/}
            {tabValue == "videos" && (
              <div className="my-favorites-column-count-3 custom-row">
                {favoritesVideoList && favoritesVideoList.length > 0
                  ? favoritesVideoList?.map((item, index) => (
                    <div className="custom-col-4 custom-col">
                      <div
                        className="my-favorites-play-card-wrap "
                        key={index}
                      >
                        <div
                          className="my-favorites-play-inside-wrap"
                          style={{ position: "relative" }}
                        >
                          {item.videoUrl ? (
                            <div
                              className="my-favorites-play-list-image"
                              onClick={() => handleVideoClick(item)}
                            >
                              <img
                                className={`my-favorites-img-banner ${getImageNameFromResolution(
                                  item?.thumbnails
                                ) != "maxresdefault" && "image-resolution"
                                  }`}
                                src={
                                  item?.thumbnails
                                    ? `${item?.thumbnails}`
                                    : `${AllImages.DefaultThumbnail}`
                                }
                                key={item.id}
                                alt="Card Image"
                                title="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="my-favorites-play-play-icon"
                                  src={AllImages.PlayIcon}
                                  alt="Play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="my-favorites-play-list-image"
                              style={{ position: "static" }}
                            >
                              <div
                              //onClick={() => takeSubcription(item)}
                              >
                                <img
                                  className={`my-favorites-img-banner ${getImageNameFromResolution(
                                    item?.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                    }`}
                                  src={
                                    item?.thumbnails
                                      ? `${item?.thumbnails}`
                                      : `${AllImages.DefaultThumbnail}`
                                  }
                                  alt="Card Image"
                                  title="Card image"
                                />
                                <div className="layerImage"></div>
                              </div>
                              <div className="star1 badgestarInvisible">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                  // onClick={() => takeSubcription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe for more Videos"}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="my-favorites-play-card-content-item">
                            <div className="my-favorites-play-video-content">
                              <div>
                                <p className="my-favorites-play-title-text f-poppins">
                                  {item.videoTitle}
                                </p>
                                <p className="my-favorites-play-text-hrs f-poppins">
                                  {MinutesToHoursMinutes(item?.videoDuration)}{" "}
                                  {item?.videoDuration ? "hrs" : "0 hrs"}{" "}
                                </p>
                              </div>
                              <div className="my-favorites-mt-10 d-flex align-center">
                                <div className="timerTooltip">
                                  <img
                                    onClick={() =>
                                      item?.watchLater
                                        ? RemoveWatchLaterVideo(item, index)
                                        : videoWatchLater(item, index)
                                    }
                                    src={
                                      item?.watchLater
                                        ? AllImages.SelectedTimer
                                        : AllImages.Timer
                                    }
                                    alt="Timer"
                                    title="timer"
                                  ></img>
                                  <span className="tooltiptext">
                                    {!item.watchLater
                                      ? "Watch Later"
                                      : "Remove Watch Later"}
                                  </span>
                                </div>
                                <div className="favTooltip">
                                  <img
                                    src={AllImages.Favorite_sel}
                                    onClick={() =>
                                      removeFavorite(item, index)
                                    }
                                    alt="Favourite"
                                    title="Favourite"
                                  ></img>
                                  <span className="tooltiptext">
                                    {"Unfavorite"}
                                  </span>
                                </div>
                                <RWebShare
                                  data={{
                                    // text: "Like humans, flamingos make friends for life",
                                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                    title: "Video Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="copyTooltip">
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Copy"}
                                    </span>
                                  </div>
                                </RWebShare>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  : !isLoading && <NoDataAvailable />}
              </div>
            )}

            {tabValue == "playlists" && (
              <div className="container watch-later my-space-mt-40">
                <div className="my-space-col-count-3 custom-row">
                  {favoritePlayListData && favoritePlayListData.length > 0
                    ? favoritePlayListData?.map((item, index) => (
                      <div className="custom-col-4 custom-col">
                        <div key={index} className="my-space-card-wrap">
                          <div className="my-space-inside-wrap">
                            <div
                              className="my-space-list-image"
                              onClick={() => {
                                individualPlayListNav(item, "admin");
                              }}
                            >
                              <img
                                className="my-space-img-banner"
                                src={`${GLOBAL_URL}/system/playlist/thumbnail/${item.id}`}
                                key={item.id}
                                alt="Card Image"
                              />
                              <div className="playIconTooltip">
                                <img
                                  className="my-space-play-icon"
                                  src={AllImages?.PlayIcon}
                                  alt="play Icon"
                                  title="Play Icon"
                                />
                                <span className="tooltiptext">
                                  {"Play Video"}
                                </span>
                              </div>
                            </div>
                            <div className="my-space-card-content-item">
                              <div className="my-space-video-content">
                                <div>
                                  <p className="my-space-title-text f-poppins">
                                    {item.playlistName}
                                  </p>
                                  <div
                                    style={{ gap: "10px" }}
                                    className="general-textDetails general-text-hrs f-poppins"
                                  >
                                    <p className="general-text-hrs">
                                      {item.videoDuration}{" "}
                                      {item.videoDuration ? "hrs" : "0 hrs"}
                                    </p>
                                    <p className="general-text-hrs">•</p>
                                    <p className="general-text-hrs">
                                      {item.noOfVideos}{" "}
                                      {item.noOfVideos
                                        ? "videos"
                                        : "0 videos"}
                                    </p>
                                  </div>
                                </div>
                                <div className="my-space-mt-10 my-space-play-list-ta-right d-flx">
                                  <div className="timerTooltip">
                                    <img
                                      onClick={() =>
                                        item.watchLater
                                          ? PlaylistWatchLaterRemove(
                                            item,
                                            index
                                          )
                                          : PlaylistWatchLater(item, index)
                                      }
                                      src={
                                        item.watchLater
                                          ? AllImages.SelectedTimer
                                          : AllImages?.Timer
                                      }
                                      alt="Timer"
                                      title="Timer"
                                    ></img>
                                    <span className="tooltiptext">
                                      {!item.watchLater
                                        ? "Watch Later"
                                        : "Remove Watch Later"}
                                    </span>
                                  </div>
                                  <div className="favTooltip">
                                    <img
                                      onClick={() =>
                                        myFavouritePlaylistRemove(item, index)
                                      }
                                      src={
                                        item.favourite
                                          ? AllImages.Favorite_sel
                                          : AllImages?.Fav
                                      }
                                      alt="Favourite"
                                      title="Favourite"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Unfavorite"}
                                    </span>
                                  </div>
                                  {/* <RWebShare
                          data={{
                            // text: "Like humans, flamingos make friends for life",
                            url: `${VideoPlaylistShareUrl}/PlayListShared/${item.id}`,
                            title: "Video Share",
                          }}
                          onClick={() => console.log('share')}
                        >
                          <img src={AllImages.Copy}></img>
                        </RWebShare> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : !isLoading && <NoDataAvailable />}
                </div>
              </div>
            )}

            {/*----- Favorite ebooks -----*/}
            {tabValue == "ebook" && (
              <div className="container watch-later my-space-mt-40">
                <div className="my-space-col-count-3 custom-row myFavouritesEbbok">
                  {eBooksFavoriteList && eBooksFavoriteList.length > 0
                    ? eBooksFavoriteList?.map((item, index) => (
                      <div className="custom-col-4 custom-col">
                        <div key={index} className="my-space-card-wrap">
                          <div className="my-space-inside-wrap">
                            <div className="my-space-list-image">
                              <img
                                className="my-space-img-banner"
                                src={item?.thumbnailImageUrl}
                                key={item.id}
                                alt="Card Image"
                              />
                            </div>
                            <div className="my-space-card-content-item">
                              <div className="my-space-video-content">
                                <div>
                                  <p className="my-space-title-text f-poppins">
                                    {item.title}
                                  </p>
                                  <div
                                    style={{ gap: "10px" }}
                                    className="ebook-textDetails ebook-text-hrs f-poppins"
                                  >
                                    {/* <p className="ebook-text-hrs">200 pages</p>
                                  <img
                                    className="dot"
                                    src={AllImages?.Dot}
                                    alt="dot"
                                    title="dot"
                                  ></img> */}
                                    {item?.pageCount && (
                                      <p className="ebook-text-hrs">
                                        {item?.pageCount} pages
                                      </p>
                                    )}
                                    {!item?.pageCount && (
                                      <p className="ebook-text-hrs">
                                        {item?.pageCount} pages
                                      </p>
                                    )}
                                    {/* <p className="ebook-text-hrs">•</p>
                                      <p className="ebook-text-hrs">
                                        {DateTimeAgo(item.publishDateTime)}
                                      </p> */}
                                  </div>
                                </div>
                                <div className="download-ebook-like-icon-div">
                                  <div className="d-flex align-center">
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                          item?.contentUrl?.replace(
                                            / /g,
                                            "%20"
                                          )
                                        )}`,
                                        title: "Ebook Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <div className="copyTooltip">
                                        <img
                                          src={AllImages.Copy}
                                          alt="Copy"
                                          title="Copy"
                                        ></img>

                                        <span className="tooltiptext">
                                          {"Copy"}
                                        </span>
                                      </div>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          myFavouriteEbooksRemove(item, index)
                                        }
                                        src={AllImages.Favorite_sel}
                                        alt="Favourite"
                                        title="Favourite"
                                      ></img>
                                      <span className="tooltiptext">
                                        {"Unfavorite"}
                                      </span>
                                    </div>
                                    {fileDownloadLoader == index ? (
                                      <img
                                        src={loader}
                                        alt="loading..."
                                        style={{ height: 20, width: 20 }}
                                      />
                                    ) : (
                                      <>
                                        <img
                                          onClick={() =>
                                            handleDownloadClick(item, index)
                                          }
                                          src={AllImages.Download}
                                          alt="Download"
                                          title="Download"
                                        ></img>
                                        <span className="tooltiptext">
                                          {"Download"}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <p
                                    onClick={() =>
                                      handleReadOrPlayClick(item.contentUrl)
                                    }
                                    className="download-ebook-read"
                                  >
                                    <div className="readTooltip">
                                      Read
                                      <span className="tooltiptext">
                                        {"Read"}
                                      </span>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : !isLoading && <NoDataAvailable />}
                </div>
              </div>
            )}
            {tabValue == "ebookSeries" && (
              <div className="container watch-later my-space-mt-40">
                <div className="my-space-col-count-3 custom-row myFavouritesEbbok ">
                  {eBookSeriesFavoriteList && eBookSeriesFavoriteList.length > 0
                    ? eBookSeriesFavoriteList?.map((item, index) => (
                      <div className="custom-col-4 custom-col">
                        <div key={index} className="my-space-card-wrap">
                          <div className="my-space-inside-wrap">
                            <div className="my-space-list-image">
                              <img
                                className="my-space-img-banner"
                                src={item?.thumbnail}
                                key={item.id}
                                alt="Card Image"
                              />
                            </div>
                            <div className="my-space-card-content-item">
                              <div className="my-space-video-content">
                                <div>
                                  <p className="my-space-title-text f-poppins">
                                    {item.playlistName}
                                  </p>
                                  <div
                                    style={{ gap: "10px" }}
                                    className="ebook-textDetails ebook-text-hrs f-poppins"
                                  >
                                    {/* <p className="ebook-text-hrs">200 pages</p>
                                  <img
                                    className="dot"
                                    src={AllImages?.Dot}
                                    alt="dot"
                                    title="dot"
                                  ></img> */}
                                    <p className="ebook-text-hrs">
                                      {item?.ebookCount ? item.ebookCount : 0}{" "}
                                      E-Books
                                    </p>
                                    {/* {!item?.ebookCount && (
                                        <p className="ebook-text-hrs">
                                          &nbsp;
                                        </p>
                                      )} */}
                                    {/* <p className="ebook-text-hrs">•</p>
                                      <p className="ebook-text-hrs">
                                        {DateTimeAgo(item.publishDateTime)}
                                      </p> */}
                                  </div>
                                </div>
                                <div className="download-ebook-like-icon-div">
                                  <div className="d-flex align-center">
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/eBookSeries/${item.id}`,
                                        title: "Ebook Series Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <div className="copyTooltip">
                                        <img
                                          src={AllImages.Copy}
                                          alt="Copy"
                                          title="Copy"
                                        ></img>

                                        <span className="tooltiptext">
                                          {"Copy"}
                                        </span>
                                      </div>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          myFavouriteEbookSeriesRemove(
                                            item,
                                            index
                                          )
                                        }
                                        src={AllImages.Favorite_sel}
                                        alt="Favourite"
                                        title="Favourite"
                                      ></img>
                                      <span className="tooltiptext">
                                        {"Unfavorite"}
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    onClick={() =>
                                      handleReadOrPlayClick(item.id)
                                    }
                                    className="download-ebook-read"
                                  >
                                    <div className="readTooltip">
                                      Open
                                      <span className="tooltiptext">
                                        {"Read"}
                                      </span>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : !isLoading && <NoDataAvailable />}
                </div>
              </div>
            )}

            {tabValue === "bhaktiPad" && (
              <div className="container watch-later my-space-mt-40 popular-bhaktiPads-page">
                {audiosFavoriteList && audiosFavoriteList.length > 0 ? (
                  <Table responsive striped>
                    <thead class="mobHide">
                      <tr>
                        <th>No.</th>
                        <th>Title</th>
                        <th class="none">Album</th>
                        <th class="none">Duration</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody class="mobHide">
                      {audiosFavoriteList?.map((item, index) => (
                        <tr key={item?.id} class="tableHover">
                          <td>
                            <div class="srNo" onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                            >
                              <p class="no mobHide">{index + 1}</p>
                              <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img"
                              // style={{
                              //   height: "60px",
                              //   width: "60px",
                              //   color: "#3a9b72",
                              // }}
                              />
                              {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                                // <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                                <div className="bhaktipadPlayIconTooltip">
                                  <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                                  <div className="bhaktipadPlayIconTooltipInner">
                                    <span className="tooltiptext">
                                      {"Play Bhakti Pad"}
                                    </span>
                                  </div>
                                </div> :
                                <div className="star1 badgestarInvisible tableLock">
                                  <div className="LikeCount badgeShow">
                                    <i
                                      className="fa fa-lock"
                                      onClick={() => takeSubscription(item)}
                                    ></i>
                                  </div>
                                  <span className="tooltiptext">
                                    {"Subscribe to use this feature"}
                                  </span>
                                </div>
                              }
                            </div>
                          </td>

                          <td>
                            <div
                              class="titleSection"
                              onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                            >
                              {/* <div class="titleSection" onClick={() => navigate('/playlist')}> */}
                              <p class="title">{item.title}</p>
                              <p class="subTitle">{item?.audioAlbum?.title}</p>
                            </div>
                          </td>
                          <td class="none">
                            <p class="album">{item?.audioAlbum?.title}</p>
                          </td>
                          <td class="none">
                            <p class="duration">
                              {formatDuration(item.duration)}
                            </p>
                          </td>
                          <td>
                            <div class="actions">
                              <div className="favTooltip">
                                <img
                                  onClick={() => myFavoriteTracksDelete(item, index)}
                                  src={
                                    item.isFavourite
                                      ? AllImages.Favorite_sel
                                      : AllImages?.Fav
                                  }
                                  alt="IMG"
                                ></img>
                                <span className="tooltiptext">
                                  {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                                </span>
                              </div>


                              {/* add to playlist */}
                              <div className="favTooltip">
                                <img
                                  className="video-play-mr-10"
                                  class="mobHide"
                                  alt="img"
                                  src={AllImages?.TablePlus}
                                  onClick={() => openModal(item?.id)}
                                ></img>
                                <span className="tooltiptext">
                                  {"Add to My Playlists"}
                                </span>
                              </div>



                              {/* three dots START */}
                              {/* <div key={item.id} className="popupOuter" ref={popupRef}>
                                <img
                                  src={AllImages?.More}
                                  className="moreImg"
                                  alt="img"
                                  onClick={() => togglePopup(item?.id)}
                                />
                              </div> */}
                              <div key={item.id} className="popupOuter moreTooltip" ref={popupRef}>
                                <img
                                  src={AllImages?.More}
                                  className="moreImg"
                                  alt="img"
                                  onClick={() => togglePopup(item?.id)}
                                  ref={popupRef}
                                />
                                <div className="moreTooltipInner">
                                  <span className="tooltiptext">
                                    {"View More"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {isPopupVisible === item.id && (
                              !isMobileView ? (
                                <div className="morePopup" ref={popupRef}>
                                  <div className="outer" onClick={() => handlePlayNext(item)}>
                                    <img src={AllImages?.Next} className="more" alt="img" />
                                    <p className="label">Play Next</p>
                                  </div>
                                  <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                    <RWebShare
                                      data={{
                                        url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                        title: 'Bhakti Pad Share',
                                      }}
                                      onClick={() => {
                                        handleCopyLink(item.id);
                                        console.log('share');
                                      }}
                                    >
                                      <div className="copyTooltip">
                                        <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                        <span className="tooltiptext">Copy</span>
                                      </div>
                                    </RWebShare>
                                    <div>
                                      <RWebShare
                                        data={{
                                          url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                          title: 'Bhakti Pad Share',
                                        }}
                                        onClick={() => {
                                          handleCopyLink(item.id);
                                          console.log('share');
                                        }}
                                      >
                                        <p className="label">Copy Link</p>
                                      </RWebShare>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mobileViewDialogOuter" ref={popupRef}>
                                  <Dialog
                                    open={togglePopup}
                                    className="sortBy mobileViewDialog"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    ref={popupRef}
                                  >
                                    <DialogContent
                                      style={{
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                      }}
                                      ref={popupRef}
                                    >
                                      <div className="qu-history-modal-msg-content">
                                        <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                        <span
                                          className="qu-history-modal-close-button"
                                          onClick={togglePopup}
                                          ref={popupRef}
                                        >
                                          &times;
                                        </span>
                                      </div>
                                      <div class="actionPopupMob" ref={popupRef}>
                                        <div class="outer tabHide" onClick={() => myFavoriteTracksDelete(item, index)} ref={popupRef}>
                                          <img
                                            src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                            alt="IMG" ref={popupRef}
                                          ></img>
                                          <p class="label">Add to Favourites</p>
                                        </div>
                                        <div class="outer tabHide" onClick={() => openModal(item?.id)} ref={popupRef}>
                                          <img
                                            className="plus"
                                            alt="img"
                                            src={AllImages?.TablePlus}
                                            ref={popupRef}
                                          />
                                          <p class="label" ref={popupRef}>Add to Playlist</p>
                                        </div>
                                        <div className="outer" onClick={() => handlePlayNext(item.id)} ref={popupRef}>
                                          <img src={AllImages?.Next} className="more" alt="img" ref={popupRef} />
                                          <p className="label" ref={popupRef}>Play Next</p>
                                        </div>
                                        <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                          <RWebShare
                                            data={{
                                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                              title: 'Bhakti Pad Share',
                                            }}
                                            onClick={() => {
                                              handleCopyLink(item.id);
                                              console.log('share');
                                            }}
                                          >
                                            <div className="copyTooltip">
                                              <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                              <span className="tooltiptext">Copy</span>
                                            </div>
                                          </RWebShare>
                                          <div>
                                            <RWebShare
                                              data={{
                                                url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                                title: 'Bhakti Pad Share',
                                              }}
                                              onClick={() => {
                                                handleCopyLink(item.id);
                                                console.log('share');
                                              }}
                                            >
                                              <p className="label">Copy Link</p>
                                            </RWebShare>
                                          </div>
                                        </div>

                                      </div>
                                    </DialogContent>
                                  </Dialog>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) :
                  <NoDataAvailable />
                }
              </div>
            )}

            {tabValue === "albums" && (
              <div className="bhaktiPadsTabBar">
                {albumFavList && albumFavList.length > 0 ? (
                  <div class="albumOuter">
                    {albumFavList?.length > 0 ? albumFavList?.map((album, index) => (
                      <div className="album"
                        key={album?.id}>
                        <div className="albumTop">
                          <img src={album.thumbnailUrl ? album.thumbnailUrl : AllImages?.Album5} alt="img" class="albumImg" />
                          <div className="albumHover">
                            <div className="albumHoverInner">
                              {/* <div className="favTooltip">
                                <img
                                  src={AllImages?.Time}
                                  className="albumInnerImg"
                                  alt="Time Icon"
                                />
                              </div> */}

                              <div className="favTooltip">
                                <img
                                  onClick={() => {
                                    handleFavoriteTracks(album, index)
                                  }}

                                  src={
                                    album.isFavourite
                                      ? AllImages.Favorite_sel
                                      : AllImages?.Heart
                                  }
                                  alt="IMG"
                                ></img>
                                <span className="tooltiptext">
                                  {!album.isFavourite ? "Mark Favorite" : "Unfavorite"}
                                </span>
                              </div>

                              <div className="favTooltip">
                                <RWebShare
                                  data={{
                                    url: `${BhaktiPadPlaylistShareUrl}/playlist/${album.id}`,
                                    title: "Playlist Share",
                                  }}
                                  onClick={() => console.log("share")}
                                >
                                  <div className="copyTooltip">
                                    <img
                                      className="cursor"
                                      src={AllImages.CopyPNG}
                                      alt="Copy"
                                      title="Copy"
                                    ></img>
                                    <span className="tooltiptext">
                                      {"Copy"}
                                    </span>
                                  </div>
                                </RWebShare>
                              </div>
                            </div>



                            <div className="albumInnerPlayIconOuter"
                              onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? navigate(`/playlist/${album?.id}`) : takeSubscription() }}>
                              {/* onClick={() => navigate(`/playlist/${album?.id}`)} > */}
                              {/* <img
                                src={AllImages?.PlayIconBig}
                                className="albumInnerPlayIcon"
                                alt="Play Icon"
                              /> */}
                              {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                                <div className="bhaktipadPlayIconTooltip">
                                  <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" alt="img" />
                                  <div className="bhaktipadPlayIconTooltipInner">
                                    <span className="tooltiptext">
                                      {"Play BhaktiPad Playlist"}
                                    </span>
                                  </div>
                                </div>
                                :
                                <div className="star1 badgestarInvisible albumLock">
                                  <div className="LikeCount badgeShow">
                                    <i
                                      className="fa fa-lock"
                                      onClick={() => takeSubscription(album)}
                                    ></i>
                                  </div>
                                  <span className="tooltiptext">
                                    {"Subscribe to use this feature"}
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="albumBottom">
                          <p className="name">{album?.title}</p>
                          <div className="desc">
                            <p class="time">{convertMinutesToHoursAndMinutes(album?.durationTotalMin) + ' hrs'}</p>
                            <p class="dot">&nbsp;</p>
                            <p class="count"> {album?.totalActiveTracks} bhakti pads</p>
                          </div>
                        </div>
                      </div>
                    ))
                      :

                      <NoDataAvailable />

                    }
                  </div>
                ) :

                  <NoDataAvailable />

                }
              </div>
            )}
          </div>
        </InfiniteScroll>
      </section >

      {isOpenSortBy && (
        <Dialog
          open={openModalSortBy}
          className="sortBy"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div className="qu-history-modal-msg-content">
              <p className="qu-history-modal-msg-text">{"Sort by"}</p>
              <span
                className="qu-history-modal-close-button"
                onClick={() => closeModalSortBy()}
              >
                &times;
              </span>
            </div>
            <ul>
              {(tabValue === "videos"
                ? Object.keys(SortingOptions)
                : [
                  "NEWLY_ADDED",
                  "LAST_MODIFIED_DATE",
                  "PREVIOUSLY_ADDED",
                  "Sort_A_To_Z",
                  "Sort_Z_To_A",
                ]
              ).map((key) => (
                <li key={key}>
                  <label className="customRadio">
                    {SortingOptions[key] === "A_TO_Z" ||
                      SortingOptions[key] === "Z_TO_A"
                      ? key.replace(/_/g, " ")
                      : capitalizeFirstWord(
                        key.replace(/_/g, " ").toLowerCase()
                      )}
                    <input
                      type="radio"
                      value={SortingOptions[key]}
                      checked={selectedFilterOp === SortingOptions[key]}
                      onChange={handleRadioChange}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
      )}
      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={"paper"}
        >
          <DialogContent
            // ref={dialogContentRef}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "320px", // Adjust the maximum height as needed
              margin: 5,
            }}
          // onScroll={handlePlaylistScroll}
          >
            <div className="add-my-playlist-modal-content">
              <div className="add-my-playlist-modal-d-flex modal-mb-5">
                <p className="add-my-playlist-modal-msg-text">
                  {"Add to My Playlists"}
                </p>
                <span
                  className="add-my-playlist-modal-close-button"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </div>
              <div>
                {userPlayList &&
                  userPlayList?.map((item, index) => (
                    <div
                      key={item.id}
                      className="add-my-playlist-modal-d-flex modal-mt-15"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox_${item.id}`}
                        name={`checkbox_${item.id}`}
                        data-playlist-id={item.id}
                        checked={isPlaylistSelected(item.id)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="add-my-playlist-checkbox-text"
                        htmlFor={`checkbox_${item.id}`}
                      >
                        {item.playListName}
                      </label>
                    </div>
                  ))}
                {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                  <p
                    onClick={fetchMoreUserPlaylistData}
                    className="fetch-more-playlist"
                  >
                    MORE+
                  </p>
                )}
              </div>
              <div className="modal-mt-15" onClick={createPlaylist}>
                <label className="add-my-playlist-create-playlist">
                  Create Playlist
                </label>
              </div>
            </div>
            {isLoading && (
              <div className="loader">
                <img src={loader} alt="loading..." />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="add-my-playlist-modal-action">
              <button
                onClick={closeModal}
                className="add-my-playlist-modal-cancel-btn"
              >
                {"Cancel"}
              </button>
              <button
                className="add-my-playlist-modal-add-btn"
                onClick={addVideoAgainstPlaylist}
              >
                {"confirm"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/*----- Create a Playlist Modal -----*/}
      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          // open={setShowCreatePlayListModel(true)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              {/*----- Modal Tab Bar -----*/}
              {/* <div>
                <Box className="my-space-tab-a-center">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#FFB200",
                        minWidth: "auto",
                      },
                    }}
                    aria-label="secondary tabs example"
                  >
                    <StyledTab value="videos" label="Satsang" />
                    <StyledTab value="bhaktiPad" label="Bhakti Pad" />
                  </Tabs>
                </Box>
              </div> */}
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  // className={`f-poppins my-create-playlist-modal-search-input ${isValid ? "invalid" : null}`}
                  className={`f-poppins my-create-playlist-modal-search-input`}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                // ref={inputRef} 
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit(tabValue)}
              // onClick={() => navigate("/myCreatePlayListTrackAddBtnSuggested")}
              >
                {/* {BtnLoading ? ( */}
                {createPlaylistBtnLding ? (
                  <img src={loader} alt="img" style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default Favorites;
