import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { addTrack, deleteTrack, deleteTracks } from "actions/Index";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import {
  CurrentTrackPlayingContext,
  MusicPlayerVisibilityContext,
  MusicTrackContext,
  MusicTracksContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  PlayNextContext,
  userDetailsContext,
  UserloggedContext
} from "components/Context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { Table } from "reactstrap";
import { generateImageDataURL } from "utils/generateImageDataURL";
import Google from "../../../assets/images/google.png";
import loader from "../../../assets/images/loading_wbg.gif";
import {
  AllImages,
  VideoPlaylistShareUrl
} from "../../../utils/constants";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import "./style.css";

function CurrentlyPlayingAudioPlaylist() {
  const navigate = useNavigate();
  const location = useLocation();
  const { playListDetails } = location.state || {};
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [musicData, setMusicData] = useState([]);
  const { track, setTrack } = useContext(MusicTracksContext);
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); 
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );
  const [isLoading, setIsLoading] = useState(false); 
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const { PlaylistId } = useParams();
  const dispatch = useDispatch();
  const [isPopupVisibleCurrentPlyalist, setPopupVisibleCurrentPlyalist] = useState(null);
  const { currentTrackPlaying, setCurrentTrackPlaying } = useContext(CurrentTrackPlayingContext);



  const SortingOptions = {
    NEWLY_ADDED: "NEWLY_ADDED",
    PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
    POPULAR_BHAKTIPAD: "POPULAR_VIDEOS",
    SERIAL_No: 'SERIAL_ASC',
    Sort_A_To_Z: "A_TO_Z",
    Sort_Z_To_A: "Z_TO_A",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterOp, setSelectedFilterOp] = useState(SortingOptions.SERIAL_No);
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const openModal = () => {
    if (auth) {
      setIsOpen(true);
    } else {
      // takeOtherSubcription();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };

  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  >
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  useEffect(() => {
    if (isOpen) {
      // fetchUserPlaylistData();
    }
  }, [isOpen]);

  const handleTitleClickAlbum = (item) => {
    // let albumId = generateAlbumIdFromTracks(item.tracks, item.title);
    if (playlistTracks?.length > 0) {
      setPlayingShuffledAlbumId(null)
      if (playingAlbumId) {
        if (playingAlbumId !== 987654321) {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingAlbumId(987654321);
          setTrack(item);
          setIsMusicPlayerVisible(true);
        } else {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingAlbumId(null);
        }
      } else {
        setTrack([]);
        setSingleTrack([]);
        setPlayNextTrack([]);
        setPlayingAlbumId(987654321);
        setTrack(item);
        setIsMusicPlayerVisible(true);
      }
    }
  };

  const shuffleArray = (item) => {
    let currentIndex = item.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [item[currentIndex], item[randomIndex]] = [
        item[randomIndex], item[currentIndex]];
    }
    return item;
  };

  const handleTitleClickAlbumShuffled = (item) => {
    if (playlistTracks?.length > 0) {
      setPlayingAlbumId(null)
      const shuffledTracks = shuffleArray([...item]);
      if (playingShuffledAlbumId) {
        if (playingShuffledAlbumId !== 123456789) {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingShuffledAlbumId(123456789);
          setTrack(shuffledTracks);
          setIsMusicPlayerVisible(true);
        } else {
          setTrack([]);
          setSingleTrack([]);
          setPlayNextTrack([]);
          setIsMusicPlayerVisible(false);
          setPlayingShuffledAlbumId(null);
        }
      } else {
        setTrack([]);
        setSingleTrack([]);
        setPlayNextTrack([]);
        setPlayingShuffledAlbumId(123456789);
        setTrack(shuffledTracks);
        setIsMusicPlayerVisible(true);
      }
    }
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);

  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);

  const deletePlaylist = () => {
    dispatch(deleteTracks()); 
    closeModal()
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

 
  
  
  const handleDragEnd = (results) => {
    const { destination, source, draggableId } = results;  
    if (!destination) return;
    const draggedTrack = playlistTracks.find((track) => track.id == draggableId);
    if (!draggedTrack) return; 
    const reorderedTracks = [...playlistTracks];
    reorderedTracks.splice(source.index, 1); 
    reorderedTracks.splice(destination.index, 0, draggedTrack);
    dispatch(addTrack(reorderedTracks));
    if(playingAlbumId == 987654321)
    setTrack(reorderedTracks);
  };

  const deleteItem = (trackId) => {
    dispatch(deleteTrack(trackId));
  };

  const toggleCurrentPlaylist = (id) => {
    setPopupVisibleCurrentPlyalist(isPopupVisibleCurrentPlyalist === id ? null : id);
  };
  
  
  return (
    <section class={`playlist-page${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      <div className="playlist-page-title-outer">
      <div className="playlist-page-title">
        <div className="my-favorites-d-flex">
          <img
            className="my-favorites-c-pointer"
            src={AllImages.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
            onClick={() => navigate(-1)}
          />
          <div className="top">
            <img
              src={generateImageDataURL('Temporary Playlist')}
              className="topImg"
            />
            <div className="padDetail">
              <p className="category">Temporary Playlist</p>
              <div className="padDetailInner">
              <p className="count">
                {playlistTracks?.length} {playlistTracks?.length === 1 ? 'bhakti pad' : 'bhakti pads'}
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div class="controlsTop">
        <div class="controlsTopInner">
          <div className="tooltipWrapper">
            <img
              src={(playingAlbumId == 987654321) ? AllImages.StopAlbum : AllImages?.PlayAlbum}
              // src={AllImages?.PlayAlbum}
              className=""
              alt="img"
              onClick={() => {
                userDetails?.subscriptionPlan?.subscriptionLevel === 2
                  ? handleTitleClickAlbum(playlistTracks)
                  : takeSubscription()
              }}
            />
            <div className="tooltip">
              <span className="tooltiptext">
                {playingAlbumId == 987654321 ? "Stop Playlist" : "Play Playlist"}
              </span>
            </div>
          </div>

          {/* <div className="tooltipWrapper">
            <img
              src={(playingShuffledAlbumId == 123456789) ? AllImages.ShuffleActive : AllImages?.Shuffle}
              // src={AllImages?.Shuffle}
              className=""
              alt="img"
              onClick={() => {
                userDetails?.subscriptionPlan?.subscriptionLevel === 2
                  ? handleTitleClickAlbumShuffled(playlistTracks)
                  : takeSubscription()
              }}
            />
            <div className="tooltip">
              <span className="tooltiptext">
                {playingShuffledAlbumId == 123456789 ? "Shuffle Active" : "Shuffle"}
              </span>
            </div>
          </div> */}

          <div className="tooltipWrapper">
            <img
              // src={(playingAlbumId == 123456789) ? AllImages.StopAlbum : AllImages?.PlayAlbum}
              src={AllImages?.DeleteCard}
              className=""
              alt="img"
              onClick={() => {
                userDetails?.subscriptionPlan?.subscriptionLevel === 2
                  ? openModal()
                  : takeSubscription()
              }}
            />
            <div className="tooltip">
              <span className="tooltiptext">
                {playingAlbumId == 123456789 ? "Delete Playlist" : "Delete Playlist"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="my-created-playlist-modal-content">
                <p className="my-created-playlist-modal-msg-text">
                  {"Are you sure you want to Delete Playlist?"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeModal()}
                >
                  &times;
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-created-playlist-modal-yes-btn"
                  onClick={() => deletePlaylist()}
                >
                  {"Yes"}
                </button>
                <button
                  className="my-created-playlist-modal-dont-delete-btn"
                  onClick={() => closeModal()}
                >
                  {"DON'T delete"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
      )}

      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Table responsive striped>
          {playlistTracks.length > 0 && (
            <thead className="mobHide">
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th className="none">Album</th>
                <th className="none">Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
          )}

          <Droppable droppableId="tbody">
            {(provided) => (
              <tbody ref={provided.innerRef} className="mobHide">
                {playlistTracks.length > 0 ? playlistTracks.map((item, index) => (
                  <Draggable
                    key={item?.id}
                    draggableId={item?.id.toString()}
                    index={index}
                    isDragDisabled={false} 
                    >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item?.id}
                        className="tableHover"
                      >
                        <td>
                          <div className="srNo"
                            onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                          >
                            <p className="no mobHide">{index + 1}</p>
                            <img src={item?.thumbnailUrl} className="bhaktiImg" alt="img" />
                            {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                              <div className="bhaktipadPlayIconTooltip">
                                <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                                <div className="bhaktipadPlayIconTooltipInner">
                                  <span className="tooltiptext">
                                    {"Play Bhakti Pad"}
                                  </span>
                                </div>
                              </div>
                              :
                              <div className="star1 badgestarInvisible tableLock">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubscription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe to use this feature"}
                                </span>
                              </div>
                            }
                          </div>
                        </td>
                        <td {...(selectedFilterOp === 'SERIAL_ASC' ? provided.dragHandleProps : {})}>
                          <div className="titleSection"
                            onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                          >
                            <p className="title">{item?.title}</p>
                            <p className="subTitle">{item?.audioAlbum?.title}</p>
                          </div>
                        </td>
                        <td className="none"><p className="album">{item?.audioAlbum?.title}</p></td>
                        <td className="none"><p className="duration">{formatDuration(item?.duration)}</p></td>


                        <td>
                          <div className="actions">
                            <div className="favTooltip">
                              <img
                                className="video-play-mr-10"
                                class="mobHide"
                                alt="img"
                                src={AllImages?.TableMinus}
                                onClick={() => deleteItem(item?.id)}
                              />
                              <span className="tooltiptext">
                              {" "}
                              {"Delete from My Playlists"}
                              </span>
                            </div>

                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                              title: "Bhakti Pad Share",
                            }}
                            onClick={() => console.log("share")}
                          >
                            <div className="favTooltip">
                              <img
                                className="video-play-mr-10 mobHide"
                                alt="img"
                                src={AllImages?.ShareOutline}
                              />
                              <span className="tooltiptext">
                                {"Share Bhakti Pad"}
                              </span>
                            </div>
                          </RWebShare>

                          <div key={item.id} className="popupOuterForCurrentPlaylist moreTooltip">
                            <img
                              src={AllImages?.More}
                              className="moreImg"
                              alt="img"
                              onClick={() => toggleCurrentPlaylist(item?.id)}
                              ref={popupRef}
                            />
                            <div className="moreTooltipInner">
                              <span className="tooltiptext">
                                {"View More"}
                              </span>
                            </div>
                            {isPopupVisibleCurrentPlyalist === item?.id && (
                            (
                            <div className="mobileViewDialogOuter"
                              ref={popupRef}>
                              <Dialog
                                open={toggleCurrentPlaylist}
                                className="sortBy mobileViewDialog"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                ref={popupRef}
                              >
                                <DialogContent
                                  style={{
                                    overflowY: "hidden",
                                    overflowX: "hidden",
                                  }}
                                  ref={popupRef}
                                >
                                  <div className="qu-history-modal-msg-content">
                                    <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                    <span
                                      className="qu-history-modal-close-button"
                                      onClick={toggleCurrentPlaylist}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div class="actionPopupMobForCurrentPlaylist"
                                    ref={popupRef}>

                                <div className="favTooltip">
                                  <div
                                    className="deleteWrapper"
                                    onClick={() => deleteItem(item?.id)}
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} // Optional inline styles
                                  >
                                    <img
                                      className="video-play-mr-10 mobHide"
                                      alt="img"
                                      src={AllImages?.TableMinus}
                                    />
                                    <p className="label">Delete from My Playlists</p>
                                  </div>
                                  <span className="tooltiptext">Delete from My Playlists</span>
                                </div>


                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                      title: "Bhakti Pad Share",
                                    }}
                                    onClick={() => console.log("share")}
                                  >
                                    <div className="favTooltip">
                                      <img
                                        className="video-play-mr-10 mobHide"
                                        alt="img"
                                        src={AllImages?.ShareOutline}
                                      /><p className="label">Share Bhakti Pad</p>
                                      <span className="tooltiptext">
                                        {"Share Bhakti Pad"}
                                      </span>
                                    </div>
                                  </RWebShare>    

                                  </div>
                                </DialogContent>
                              </Dialog>
                            </div>
                            )
                            )}
                          </div>

                          </div>
                        </td>


                        
                      </tr>
                    )}
                  </Draggable>
                )) : (
                  <tr>
                  <td colspan="5" style={{ textAlign: 'center' }}>
                    {isLoading ? (
                      <img
                        src={loader}
                        alt="loading..."
                         style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    ) : (
                      <NoDataAvailable />
                    )}
                  </td>
                </tr>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>


    </section>
  );
}

export default CurrentlyPlayingAudioPlaylist;
