import React, { useEffect } from "react";
// 👇️ import Routes instead of Switch 👇️
import Routes from "./routes";
import {
  VideoListProvider,
  SearchVideoListProvider,
  SearchTextProvider,
  UserDetailsProvider,
  FavoritesVideoListProvider,
  SubcriptionDetailProvider,
  SubcriptionHistoryProvider,
  SubcriptionListProvider,
  SearchLoaderProvider,
  PageReloadProvider,
  GSTProvider,
  SelectedSubcriptionProvider,
  SearchFiterClearProvider,
  VideoIndexProvider,
  SearchHistoryProvider,

  // second phase
  AllVideoPlayListProvider,
  LoaderProvider,
  UserloggedProvider,
  GoogleLoginShowProvider,
  WatchLaterVideosProvider,
  UserPlayListProvider,
  RecentWatchVideosListProvider,
  SharedPlayListProvider,
  MyFavoritePlayListProvider,
  ShowCreatePlayListProvider,
  RecentlyPlayListProvider,
  EBooksListProvider,
  EBooksFavoriteListProvider,
  AudiosFavoriteListProvider,
  EBooksSeriesFavoriteListProvider,
  SuggestedVideoProvider,
  SurpriseMeProvider,
  SurpriseMeDataProvider,
  GetDowloadedEbookProvider,
  ChangeFAQsLangProvider,
  AdvanceSearchTabProvider,
  AdvanceSearchDataProvider,
  AdvanceFilterValProvider,
  AdvanceSearchTextProvider,
  AdvanceNoDataFoundProvider,
  ActiveTabsProvider,
  SatsangAllLocationProvider,
  QuestionHistoryProvider,
  SatsangAllSpeakersProvider,
  EBookSeriesProvider,
  MusicPlayerVisibilityProvider,
  MusicTracksProvider,
  AlbumFavProvider,
  MusicTrackProvider,
  PlayNextProvider,
  CurrentTrackPlayingProvider,
  MusicTracksTestProvider,
  PlayingAlbumIdProvider,
  PlayingShuffledAlbumIdProvider,
  CurrentTrackIndexProvider,
  MusicProvider,
  StopVideoTimeProvider,
  MusicDataProvider,
  MusicPlayBlockProvider,
} from "./components/Context/UserContext";
import { ToastContainer, toast } from "react-toastify";


import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import main from "NotificationService";

export default function App() {

  // useEffect(() => {
  //   main();
  // }, []);

  return (
    <VideoListProvider>
      <SearchVideoListProvider>
        <SearchTextProvider>
          <UserDetailsProvider>
            <FavoritesVideoListProvider>
              <SubcriptionDetailProvider>
                <SubcriptionHistoryProvider>
                  <SubcriptionListProvider>
                    <SearchLoaderProvider>
                      <PageReloadProvider>
                        <GSTProvider>
                          <SelectedSubcriptionProvider>
                            <SearchFiterClearProvider>
                              <VideoIndexProvider>
                                <SearchHistoryProvider>
                                  {/* second phase */}
                                  <EBooksSeriesFavoriteListProvider>
                                    <AllVideoPlayListProvider>
                                      <LoaderProvider>
                                        <UserloggedProvider>
                                          <GoogleLoginShowProvider>
                                            <WatchLaterVideosProvider>
                                              <UserPlayListProvider>
                                                <RecentWatchVideosListProvider>
                                                  <SharedPlayListProvider>
                                                    <MyFavoritePlayListProvider>
                                                      <ShowCreatePlayListProvider>
                                                        <RecentlyPlayListProvider>
                                                          <EBooksListProvider>
                                                            <EBooksFavoriteListProvider>
                                                              <AudiosFavoriteListProvider>
                                                                <SuggestedVideoProvider>
                                                                  <SurpriseMeProvider>
                                                                    <SurpriseMeDataProvider>
                                                                      <GetDowloadedEbookProvider>
                                                                        <ChangeFAQsLangProvider>
                                                                          <AdvanceSearchTabProvider>
                                                                            <AdvanceSearchDataProvider>
                                                                              <AdvanceFilterValProvider>
                                                                                <AdvanceSearchTextProvider>
                                                                                  <AdvanceNoDataFoundProvider>
                                                                                    <ActiveTabsProvider>
                                                                                      <SatsangAllLocationProvider>
                                                                                        <QuestionHistoryProvider>
                                                                                          <SatsangAllSpeakersProvider>
                                                                                            <EBookSeriesProvider>
                                                                                              <MusicTrackProvider>
                                                                                                <MusicPlayerVisibilityProvider>
                                                                                                  <MusicTracksProvider>
                                                                                                    <PlayNextProvider>
                                                                                                      <CurrentTrackPlayingProvider>
                                                                                                        <MusicTracksTestProvider>
                                                                                                          <MusicProvider>
                                                                                                            <MusicDataProvider>
                                                                                                              <AlbumFavProvider>
                                                                                                                <PlayingAlbumIdProvider>
                                                                                                                  <PlayingShuffledAlbumIdProvider>
                                                                                                                    <CurrentTrackIndexProvider>
                                                                                                                      <StopVideoTimeProvider>
                                                                                                                        <MusicPlayBlockProvider>
                                                                                                                         <Routes />
                                                                                                                         <ToastContainer />
                                                                                                                        </MusicPlayBlockProvider>
                                                                                                                      </StopVideoTimeProvider>
                                                                                                                    </CurrentTrackIndexProvider>
                                                                                                                  </PlayingShuffledAlbumIdProvider>
                                                                                                                </PlayingAlbumIdProvider>
                                                                                                              </AlbumFavProvider>
                                                                                                            </MusicDataProvider>
                                                                                                          </MusicProvider>
                                                                                                        </MusicTracksTestProvider>
                                                                                                      </CurrentTrackPlayingProvider>
                                                                                                    </PlayNextProvider>
                                                                                                  </MusicTracksProvider>
                                                                                                </MusicPlayerVisibilityProvider>
                                                                                              </MusicTrackProvider>
                                                                                            </EBookSeriesProvider>
                                                                                          </SatsangAllSpeakersProvider>
                                                                                        </QuestionHistoryProvider>
                                                                                      </SatsangAllLocationProvider>
                                                                                    </ActiveTabsProvider>
                                                                                  </AdvanceNoDataFoundProvider>
                                                                                </AdvanceSearchTextProvider>
                                                                              </AdvanceFilterValProvider>
                                                                            </AdvanceSearchDataProvider>
                                                                          </AdvanceSearchTabProvider>
                                                                        </ChangeFAQsLangProvider>
                                                                      </GetDowloadedEbookProvider>
                                                                    </SurpriseMeDataProvider>
                                                                  </SurpriseMeProvider>
                                                                </SuggestedVideoProvider>
                                                              </AudiosFavoriteListProvider>
                                                            </EBooksFavoriteListProvider>
                                                          </EBooksListProvider>
                                                        </RecentlyPlayListProvider>
                                                      </ShowCreatePlayListProvider>
                                                    </MyFavoritePlayListProvider>
                                                  </SharedPlayListProvider>
                                                </RecentWatchVideosListProvider>
                                              </UserPlayListProvider>
                                            </WatchLaterVideosProvider>
                                          </GoogleLoginShowProvider>
                                        </UserloggedProvider>
                                      </LoaderProvider>
                                    </AllVideoPlayListProvider>
                                  </EBooksSeriesFavoriteListProvider>
                                  {/* second phase */}
                                </SearchHistoryProvider>
                              </VideoIndexProvider>
                            </SearchFiterClearProvider>
                          </SelectedSubcriptionProvider>
                        </GSTProvider>
                      </PageReloadProvider>
                    </SearchLoaderProvider>
                  </SubcriptionListProvider>
                </SubcriptionHistoryProvider>
              </SubcriptionDetailProvider>
            </FavoritesVideoListProvider>
          </UserDetailsProvider>
        </SearchTextProvider>
      </SearchVideoListProvider>
    </VideoListProvider>
  );
}
